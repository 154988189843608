import React, { useEffect, useState, useRef } from "react";
import { Card, Button, Result } from "antd";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axiosInstance from "../helper/axios";
import { LoadingOutlined } from "@ant-design/icons";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentVerifier, setPaymentVerifier] = useState(false);
  const [loading, setLoading] = useState(false);
  // Get the reference_number from the query string
  const queryParams = new URLSearchParams(location.search);
  const query = useQuery();

  const referenceNumber = queryParams.get("ref");
  const paymentRef = query.get("ref");
  const hasUpdatedRef = useRef(false);
  const [riderData, setRiderData] = useState([]);
  // const updateDbStatuses = async (paymentRef) => {
  //   try {
  //     const { data } = await axiosInstance().patch(
  //       "/api/rider/success-package-payment",
  //       {
  //         referrence_number: paymentRef,
  //       }
  //     );

  //     if (data) {
  //       setRiderData(data);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(">>>>> error updating database statuses");
  //   }
  // };

  const paymentChecker = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance().patch(
        "/api/rider/success-package-payment",
        {
          referrence_number: referenceNumber,
        }
      );
      if (data) {
        const response = await axiosInstance().put(
          `/api/mothly-subscription/payment-checker`,
          {
            reference_number: referenceNumber,
            rider_id: data.data.id,
          }
        );
        if (response.data.message !== undefined) {
          setPaymentVerifier(true);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Payment Checker : >>> ", error);
    }
  };

  useEffect(() => {
    if (paymentRef && !hasUpdatedRef.current) {
      hasUpdatedRef.current = true;

      paymentChecker();
    }
  }, [paymentRef]);

  return (
    <div
      style={{
        backgroundColor: "#00BF63",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
          backgroundColor: "#00BF63",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 700,
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingOutlined style={{ fontSize: 45, color: "#1E90FF" }} />
              <p style={{ fontSize: 20 }}>
                PLease wait while the system is verifying your payment.
              </p>
            </div>
          ) : (
            <Result
              status="success"
              title={
                <div>
                  <>
                    <p style={{ fontSize: 25 }}>
                      Payment successfully verified
                    </p>
                    <div>
                      You can now try to log in your rider account in our Dory
                      rider app
                    </div>
                    <p style={{ fontSize: 20 }}>
                      Referrence number : {referenceNumber}
                    </p>
                  </>
                </div>
              }
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default PaymentSuccess;
