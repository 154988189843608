import React, { useEffect } from "react";
import { useNavigation } from "react-router-dom";
import { Result, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../helper/axios";

const PaymentMonthlySub = () => {
  //   const navigate = useNavigation();
  const location = useLocation();
  const navigate = useNavigate();

  const { data } = location.state || {};
  console.log("data : >>> ", data);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "100vh",
        padding: "0 10px",
        boxSizing: "border-box",
      }}
    >
      <div style={{ marginTop: 120 }}>
        <Result
          title="Your account is already registered. But you need to avail a monthly subscription to continue."
          extra={
            <Button
              type="primary"
              key="console"
              style={{ width: "70%", backgroundColor: "#00BF63" }}
            >
              Continue
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default PaymentMonthlySub;
