import React, { useState, useEffect, useRef } from "react";
import { Result, Spin, Drawer, Button, Image } from "antd";
import axiosInstance from "../helper/axios";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import gmail from "../assets/gmail.png";
import playstore from "../assets/playstore.png";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SuccessPage = () => {
  const query = useQuery();
  const paymentRef = query.get("ref");
  // const riderUserId = query.get("id");

  const [loading, setLoading] = useState(true);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [riderData, setRiderData] = useState([]);
  const hasUpdatedRef = useRef(false);

  // useEffect(() => {
  //   const payment = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await axiosInstance().post(
  //         `/api/mothly-subscription/web-site/payment`,
  //         {
  //           reference_number: paymentRef,
  //         }
  //       );
  //       console.log(response.data);
  //       window.location.href = response.data.paymentLink;
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.log("ERROR payment : >>> ", error);
  //     }
  //   };
  //   payment();
  // }, []);

  const updateDbStatuses = async (paymentRef) => {
    try {
      const { data } = await axiosInstance().patch(
        "/api/rider/success-package-payment",
        {
          referrence_number: paymentRef,
        }
      );

      if (data) {
        setRiderData(data);
        window.location.href = "https://m.me/299192866607520?ref=1743662666983";
      }
    } catch (error) {
      setLoading(false);
      console.log(">>>>> error updating database statuses");
    }
  };

  useEffect(() => {
    if (paymentRef && !hasUpdatedRef.current) {
      hasUpdatedRef.current = true;

      updateDbStatuses(paymentRef);
    }
  }, [paymentRef]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Result
          status="success"
          title="Thank you for signing up"
          subTitle="Your account is now registered."
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            flexWrap: "wrap",
            justifyContent: "center ",
            marginTop: -50,
          }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: 10,
              marginBottom: 30,
              color: "gray",
            }}
          >
            <p>
              Thank you for registering as a Dory Rider! You will be redirected
              to our messenger contact for further assistance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
