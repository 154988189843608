import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RiderSignUp from "./page/rider-sgnup";
import ErrorPage from "./page/404.page";
import SuccessPage from "./page/success.page";
import FailedPage from "./page/failed.page";
import OtpPage from "./page/otp.page";
import PaymentMonthlySub from "./page/rider-payment-monthly-sub";
import PaymentSuccess from "./page/payment-success.page";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RiderSignUp />} />
        <Route path="/404" element={<ErrorPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/failed" element={<FailedPage />} />
        <Route path="/otp" element={<OtpPage />} />
        <Route path="/payment" element={<PaymentMonthlySub />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
      </Routes>
    </Router>
  );
}

export default App;
